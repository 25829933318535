import Vue from 'vue';
import axios from "axios";
import {Message} from 'element-ui';
axios.defaults.baseURL = 'https://www.xiaoyutai.com'
// axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem("user_id");
axios.defaults.timeout = 60 * 1000; 
const request = axios.create();
request.interceptors.response.use(res => {
	// console.log("response",res)
	if(res.data.code!=200){
		Message.error(res.data.message)
	}
	return res.data
});
request.interceptors.request.use(config => {
	// console.log("config",config)
	if(!config.data){
		config.data={} 
	}
	config.data.user_id = localStorage.getItem("user_id")
	return config
}, error => {
	Message.error(error)
	console.log("requesterror",error)
	Promise.reject(error)
})
Vue.prototype.$request = request;