<template>
	<div class="message_modal_1" :class="show?'show':''" @click="mask_click()">
		<div class="message_modal_content" @click.stop :style="{'background-color':bgColor}">
			<slot></slot>
		</div>
	</div>
</template>

<script>
	export default{
		props:{
			show:{},
			mask_click_hide:{
				default:false
			},
			bgColor:{
				default:"#fff"
			}
		},
		methods:{
			mask_click(){
				if(this.mask_click_hide){
					this.$emit("close")
				}
			}
		}
	}
</script>

<style lang="scss">
	.message_modal_1{
		position: fixed;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		margin: auto;
		width:0;
		height: 0;
		z-index: -99999;
		background-color: rgba(0,0,0,0);
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		transition: background-color 0.3s;
		overflow: hidden;
		padding: 20px ;
		// overflow-y: scroll;
		.message_modal_content{
			// background: #FFFFFF;
			border-radius: 8px 8px 8px 8px;
		}
		&.show{
			width: 100vw;
			height: 100vh;
			z-index: 900;
			background-color: rgba(0,0,0,0.5);
		}
	}
</style>