import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		user_id:""
	},
	getters: {
		
	},
	mutations: {
		USER_SET(state,options){
			for(let i in options){
				state[i]=options[i]
			}
		}
	},
	actions: {
	},
	modules: {
	}
})