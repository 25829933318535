// 生成随机串
export const generateRandomString = () => {
	const randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

	let result = 'XYT';
	const timestamp = new Date().toISOString().replace(/[-:.]/g, '').replace('T', '');
	result += timestamp;

	for (let i = 0; i < 9; i++) {
		const randomIndex = Math.floor(Math.random() * randomChars.length);
		result += randomChars.charAt(randomIndex);
	}

	return result;
};