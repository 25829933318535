<template>
	<div class="header_view_1">
		<div class="header" id="header">
			<div class="common_view">
				<div class="left">
					<img src="@/assets/images/02.png" class="logo" alt="" srcset="">
					<p class="p1">XPaper Ai</p>
					<p class="p2">论文写作辅助指导平台</p>
				</div>
				<div class="right" v-if="plat_info">
					<p class="p1">累计访客 {{plat_info.outlineCount}}，今日有{{plat_info.paperCount}}个用户生成了参考论文</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default{
		props:{
			plat_info:{}
		},
		data() {
			return{
				header_height:0
			}
		},
		mounted() {
			this.get_height()
		},
		updated() {
			this.get_height()
		},
		methods:{
			get_height(){
				var element = document.getElementById("header");
				var height = element.offsetHeight;
				// console.log(height);
				this.header_height=height;
				this.$emit("get_height",this.header_height)
			}
		}
	}
</script>

<style lang="scss">
	.header_view_1{
		width: 100%;
		height: 58px;
		.header{
			background-color: #fff;
			// position: fixed;
			width: 100%;
			height: 100%;
			left: 0;
			top: 0;
			z-index: 900;
			.common_view{
				display: flex;
				justify-content: space-between;
				align-items: center;
				height: 100%;
				.right{
					.p1{
						font-size: 12px;
						font-family: HarmonyOS Sans, HarmonyOS Sans;
						font-weight: 400;
						color: #101C3D;
					}
				}
				.left{
					display: flex;
					align-items: center;
					.logo{
						width: 42px;
						height: 42px;
					}
					.p1{
						font-size: 18px;
						font-family: Helvetica, Helvetica;
						font-weight: 700;
						color: #101C3D;
						margin-left: 10px;
					}
					.p2{
						font-size: 12px;
						font-family: HarmonyOS Sans, HarmonyOS Sans;
						font-weight: 400;
						color: #101C3D;
						margin-left: 10px;
					}
				}
			}
		}
	}
</style>