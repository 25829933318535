import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false;

  // moment插件

console.log = function(message) {  
  // 在这里可以对打印信息进行自定义处理，例如隐藏或过滤掉不需要的信息  
}


import moment from 'moment'
moment.locale('zh-cn');
Vue.prototype.$moment = moment

// element插件
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI);

// import Axios from 'axios'
// Vue.prototype.$axios=Axios
// localStorage.setItem("tenant","MDAwMA==");

// 跳转移动端
function redirectToMobile() {  
	let params=window.location.search;
  if (/Mobi|Android/i.test(navigator.userAgent)) {  
    const newUrl = `https://m.130ai.com${params}`;  
    window.location.href = newUrl;  
  }  
}
redirectToMobile();


// 获取页面参数
function getParameterByName(name) {
  const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
  const results = regex.exec(window.location.search);

  if (!results || !results[2]) return "";
	
	let result=decodeURIComponent(results[2].replace(/\+/g, ' '))
  return result?result:"";
}
sessionStorage.setItem("source",getParameterByName('source'))  

import Header from "@/components/Header.vue";
Vue.component("Header",Header)

import Footer from "@/components/Footer.vue";
Vue.component("Footer",Footer);

import MessageModal from "@/components/MessageModal.vue";
Vue.component("MessageModal",MessageModal);


// import $request from "@/api/request";
// Vue.prototype.$request=$request;

import "./api/request.js";



// 创建eventBus
Vue.prototype.$EventBus = new Vue();

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
