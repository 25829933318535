<template>
	<div id="app">
		<router-view />
	</div>
</template>

<script>
	import {generateRandomString} from "@/utils/util.js";
	export default {
		created() {
			const user_id=localStorage.getItem("user_id")|| generateRandomString();
			// console.log("user_id",user_id);
			this.$store.commit("USER_SET",{
				user_id
			})
			localStorage.setItem("user_id",user_id)
			// console.log(this.$store.state)
			// console.log(this.$axios)
		}
	}
</script>

<style lang="scss">
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
	}
	a{
		text-decoration: none;
	}

	body,
	#app {
		min-width: 1280px;
		// overflow-x: scroll;
	}

	.common_view {
		width: 1200px;
		margin: 0 auto;
	}

	.custom_input {
		>* {
			width: 100%;
			height: 100%;

			.el-input {
				width: 100%;
				height: 100%;

				input,
				input.el-input_inner {
					width: 100%;
					height: 100% !important;
				}
			}
		}
	}

	.text_ellipsis {
		white-space: nowrap;
		/* 防止文字换行 */
		overflow: hidden;
		/* 隐藏超出部分的文本 */
		text-overflow: ellipsis;
		/* 当文本溢出时，显示省略号 */
	}
</style>