<template>
	<div class="footer_view_1">
		<div class="links" v-if="plat_info&&plat_info.link&&plat_info.link.length">
			<div class="common_view">
				<span class="label">友情链接 :</span>
				<a :href="item.url" target="_blank" class="link" v-for="(item,index) in plat_info.link" :key="index">{{item.name}}</a>
			</div>
		</div>
		<p class="p1">Copyright © 2023-2024 XPaper Ai. All Rights Reserved  <a style="color:#9D9D9D;" href="https://beian.miit.gov.cn/" target="_blank">京ICP备2023014282号-3</a> </p>
	</div>
</template>

<script>
	export default{
		data(){
			return {
				plat_info:null
			}
		},
		created() {
			this.get_plat_info()
		},
		methods:{
			// 获取平台信息
			get_plat_info() {
				this.$request
					.get('api/paper/countInfo')
					.then(res => {
						console.log('获取平台信息', res);
						this.plat_info = res.data;
						// outline_step_ref.close()
					})
					.catch(() => {
						console.log('出错啦');
					});
			},
		}
	}
</script>

<style lang="scss">
	.footer_view_1{
		padding: 40px 0;
		width: 100%;
		.links{
			border-bottom: 1px solid  #C4C7DE;
			padding: 20px 0;
			margin-bottom: 20px;
			width: 100%;
			.common_view{
				text-align: center;
				.label,.link{
					font-size: 14px;
					font-family: Source Han Sans, Source Han Sans;
					font-weight: 400;
					color: rgba(16,28,61,0.502);
					margin-right: 20px;
					&.label{
						margin-right: 30px;
					}
					&.link:hover{
						color: #2051ff;
					}
				}
			}
		}
		.p1{
			font-size: 12px;
			font-family: Source Han Sans, Source Han Sans;
			font-weight: 400;
			color: #9D9D9D;
			text-align: center;
			padding: 20px 0;
			
		}
	}
</style>